export const resources_template = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      template:{
        essential: {
          title: 'Essential',
          personnal_budget: 'Personal expenses',
          business_simple: 'Simple business accounting',
          commercial_pipe: 'Business pipe',
          production_of_boats: 'Sailboats production'
        },
      },
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      template:{
        essential: {
          title: 'Basique',
          personnal_budget: 'Dépenses personnelles',
          business_simple: 'Bilan comptable simple',
          commercial_pipe: 'Pipe commercial',
          production_of_boats: 'Production'
        },
      },
    }
  }
}

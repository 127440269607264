export const resources_app_elements = {
  en: {
    translation: {
      Menu: {
        presentation_OS: 'OpenSankey is a web application that makes it easy to create Sankey diagrams.\n\n It\'s available free of charge, and includes simple functions for creating nodes, flows and labeling them to aggregate or filter their display on the diagram.\n\n The 15-node limit for use without an account can be lifted by creating a license-free account.\n\n',
        presentation_OS_limit_node: 'The 15-node limit for use without an account can be lifted by creating a free account.\n\n',
        Aucun: 'None',
        confSankey: 'Sankey\'s configuration',
        MEP: 'Drawing zone',
        background: 'Background',
        Noeuds: 'Nodes',
        EN: 'Nodes\' tags',
        EdN: 'Editing nodes',
        flux: 'Links',
        EF: 'Links\' tags',
        EdF: 'Editing links',
        ED: 'Data\'s tags',
        Leg: 'Legends',
        LegX: 'Horizontal position',
        LegY: 'Vertical position',
        LegWidth: 'Width',
        larg: 'Width',
        haut: 'Height',
        Fichiers: 'Files',
        mep: 'Layout',
        formation: 'Tutorials',
        Exemples: 'Demos',
        Aide: 'Help',
        ouvrir: 'Open',
        enregistrer: 'DL',
        exporter: 'Export',
        exporter_to: 'To ',
        preference: 'Settings',
        esn: 'Edit nodes style',
        esf: 'Edit links style',
        rc: 'Keyboard Shortcuts',
        as: 'Additional Help',
        doc: 'Doc.',
        ca: 'Path',
        annuler: 'Cancel',
        submit: 'Submit',
        pdd: 'Publish/Update diagram',
        fmep: 'Layout file',
        ad: 'Apply the layout',
        ns: 'Style name',
        BgC: 'Color',
        dns: 'Display only visible nodes in the nodes selector',
        dls: 'Display only visible links in the links selector',
        Tags: 'Tags',
        PosNoeud: 'Node geometry',
        PosFlux: 'Flux Geometry',
        attrNode: 'Node Attributes',
        attrFlux: 'Flux Attributes',
        tagLevel: 'Level of Detail',
        tagNode: 'Node Tags',
        tagFlux: 'Flux Tags',
        tagData: 'Data Tags',
        tagFlux_assign: 'Assign a link tag',
        SaveJSON: 'Save as JSON',
        SaveValue: 'Save with links\' values',
        VisibleElement: 'Only save visible elements',
        templates: 'Templates',
        warningLimitNode: 'Warning : Limit of node reach for unconnected user',
        licence: 'Licences',
        display_scale: 'Display link scale',
        fontSize: 'Font size',
        file: 'Files',
        diagramme: 'Diagrams',
        edition: 'Formatting',
        demo: 'Sankeylib',
        aide: 'Help',
        filter: 'Filter',
        toolbar: 'Tools',
        check: 'Check',
        last_save: 'Last save',
        style: 'Styles',
        close: 'Close',
        Elements: 'Elements',
        Etiquettes: 'Editing tags',
        reinit: 'Reset',
        amp_short: 'Layout',

        ajustH: 'Adjust. H',
        ajustV: 'Adjust. V',

        rcc_titre_princ: 'OpenSankey Application Shortcuts',
        rcc_titre_select: 'With the mouse in selection mode',
        rcc_cn_bold: 'Click (nodes)',
        rcc_cn: 'Select uniquely the clicked node',
        rcc_shift_cn_bold: 'Shift + Click (nodes)',
        rcc_shift_cn: 'Select the clicked node and open the "Nodes" tab of the menu',
        rcc_ctrl_cn_bold: 'CTRL + Click (nodes)',
        rcc_ctrl_cn: 'Add clicked node to list of selected nodes',
        rcc_cf_bold: 'Click (link)',
        rcc_cf: 'Selects the clicked link',
        rcc_shift_cf_bold: 'Shift + Click (link)',
        rcc_shift_cf: 'Selects the clicked link and opens the "Link" tab of the menu',
        rcc_ctrl_cf_bold: 'CTRL + Click (link)',
        rcc_ctrl_cf: 'Add clicked link to list of selected links',
        rcc_cs_bold: 'Click (outside of a node/link)',
        rcc_cs: 'Deselects the selected nodes and links',
        rcc_click_and_drag_bold: 'Click & drag  (drawing area)',
        rcc_click_and_drag: 'Create a selection zone that select nodes included inside',
        rcc_cdn_bold: 'Right click (nodes,links,drawing area)',
        rcc_cdn: 'Open a contextual menu to apply quick changement tot the elements or open some modals',
        rcc_ad_bold: 'Alt Drag (label nodes)',
        rcc_ad: 'Moves the label',
        rcc_titre_edi: 'With the mouse in edit mode',
        rcc_e_cn_bold: 'Click (drawing area)',
        rcc_e_cn: 'Adds a node where clicked',
        rcc_e_ds_bold: 'Drag (from drawing area)',
        rcc_e_ds: 'Creates a node at the drag start point and then creates a link from the node created to: either a node already existing if you drop on it, or a node that you create at the place where you drop on the drawing area',
        rcc_e_dn_bold: 'Drag (from a node)',
        rcc_e_dn: 'Create a link from the drag start node: either a node already existing if you drop on it, or a node that you create where you drop on the drawing area',
        rcc_titre_autre: 'Other shortcuts',
        rcc_a_s_bold: 'Delete',
        rcc_a_s: 'Removes selected nodes and links',
        rcc_a_fc_bold: 'Keyboard arrow',
        rcc_a_fc: 'Move the selected nodes according to the mesh',
        rcc_a_dbm_bold: 'Drag (middle mouse button and outside a node/link)',
        rcc_a_dbm: 'Moves the full sankey',
        rcc_a_ech_bold: 'Esc:',
        rcc_a_ech: 'Closes the Menu when it is open and resets the mouse modeto selection',
        rcc_titre_OSP: 'OpenSankey+ module Shortcuts',
        rcc_osp_cs_bold: 'CTRL+X',
        rcc_osp_cs: 'Creates a view which can then be modified. To keep the modifications applied to the view, save it with Ctrl+S.',
        rcc_osp_ctrl_czdt_bold: 'CTRL+Click (Text Box)',
        rcc_osp_ctrl_czdt: 'Selects the clicked area and opens the text box edit menu',
        rcc_F7_bold: 'F7',
        rcc_F7: 'Switche to master data',
        rcc_F8_bold: 'F8',
        rcc_F8: 'Switche to previous view',
        rcc_F9_bold: 'F9',
        rcc_F9: 'Switch to next view',
        rcc_ctrl_scrll_bold: 'CTRL+scroll (sankey draw zone)',
        rcc_ctrl_scrll: 'Zoom/Unzoom in the sankey zone',
        support: 'Contact',
        c_support: 'Contact the support',
        rth_support: 'We are here to help you',
        support_explication: 'If you encounter a technical problem, write to us at [] and we will deal with your request as quickly as possible.',
        suppr: 'Delete',
        rand_node_color: 'Assign random color to all nodes',
        load_file: 'Load file',
        compute_file: 'Computing',
        loaded_file: 'File loaded',
        failure_file: 'Loading failed',
        choseTransforDifficulty: 'Types d\'éléments',
        selectNodeAttrubutedToTag: 'Select all nodes having this tag',
        selectLinkAttrubutedToTag: 'Select all links having this tag',
        node_label_sep: 'Character(s) limiting node name on the sankey',
        node_label_sep_pos: 'Select text to display (before or after separator)',
        before: 'Before',
        after: 'After',
        from_new: 'Empty diagram',
        from_model: 'From a model',
        open_json: 'Sankey diagram (JSON file)',
        open_excel: 'Data (Excel file)',
        open_sankeymatic: 'SankeyMATIC (Text file)',
        title_pref: 'Application setting',
        lang: 'Language',
        new: 'New',
        pref_title_sub_menu: 'Choose sub-menu visible in configuration menu',
        overcharge_style_value: 'Value of the variable override the value from the style',
        group: 'Tag group',
        color: 'Color',
        LegBgOpacity: 'Opacity',
        LegBgBorder: 'Border',
        LegBgColor: 'Color',
        open_excel_file: 'Open an excel file',
        input_file_excel: 'Input file',
        no_input_file_detected: 'No file has been selected',
        waiting: 'Please wait',
        old_app: 'Legacy app.',
        Transformation: {
          amp_short: 'Layout',
          amp: 'Change the layout',
          amp_import: 'From other diagram',
          amp_manual: 'Positioning',
          trans_topo: 'Topological',
          fmep: 'Source diagram',
          ad: 'Apply',
          undo: 'Cancel',
          Shortcuts: 'Shortcuts selections',
          unSelectAll: 'None',
          selectAll: 'All',
          selectDefault: 'Default',
          Topology: 'Additions and deletions',
          Geometry: 'Sizes and positions',
          Attribut: 'Attributes',
          Tags: 'Tags',
          Values: 'Link values',
          Views: 'Views',
          freeLabels: 'Text boxes',
          addNode: 'Aj. Nodes',
          removeNode: 'Sup. Nodes',
          addFlux: 'Aj. Flux',
          removeFlux: 'Sup. Flux',
          PosOut: 'Nodes',
          posFlux: 'Flux',
          attrNode: 'Nodes',
          attrFlux: 'Flux',
          tagLevel: 'Levels of detail',
          tagNode: 'Nodes',
          tagFlux: 'Flux',
          tagData: 'Data',
          tagNode_assign: 'Assign a label',
          tagFlux_assign: 'Assign a label',
          attrGeneral: 'Drawing area',
          title: 'Layout',
          oher_file: 'Other file',
          tooltips: {
            Shortcuts: 'Shortened selection of attributes to transfer from imported sankey',
            Topology: 'Transfer added/deleted node/links from the imported sankey to current',
            Geometry: 'Transfer nodes & flux position attributes from the sankey  imported to the current',
            Attribute: 'Transfer attributes of nodes and links from imported sankey to current',
            Tags: 'Transfer node/links tags from imported sankey to current',
            Values: 'Transfer the values of the links of the imported sankey to the current',
            tagLevel: 'Transfer nodes details of imported sankey to current',
            attrGeneral: 'Transfer the attributes of the drawing area of the imported sankey to the current',
          }
        },
        tooltips: {
          noeud: {
            slct: 'Choose one / some / all nodes to select via a drop-down list',
            plus: 'Add a node, which will be automatically selected.',
            rm: 'Allow to delete all currently selected nodes',
            dns: 'Display in the node selection list, only the node(s) that is/are currently visible in the graphic space'
          },
          flux: {
            slct: 'Choose one / some / all link(s) to select via a drop-down list',
            plus: 'Add a new link. This one will be automatically selected.',
            rm: 'Allow to delete currently selected link(s)',
            dls: 'Display in the list of selection of link(s), only the link(s) that is/are currently visible on the graphic space',
          },
          LegX: 'Horizontal distance (in px) between the upper left corner of the legend and the upper left corner of the window',
          LegY: 'Vertical distance (in px) between the upper left corner of the legend and the upper left corner of the window',
          LegWidth: 'Width (in px) for the legend space',
          BgC: 'Change the background color of the interactive graphic space',
          checkpoint: 'Local data saving on the browser (cache memory)',
          fontSize: 'Change the size of the font in the legend',
          node_label_sep: 'String of text that will be used to hide some part of a node label, exemple: separator is " - " and node name is "node1 - subinfo" the node label displayed in the drawing area will be "node1"',
          node_label_sep_pos: 'Select wich part of nodes label to display (before or after separator)',
          new: 'Create a new sankey diagram',
          ouvrir: 'Open a file',
          enregistrer: 'Save as',
          preference: 'Set parameters of the application',
          amp: 'Modify the layout of the diagram',
          style: 'Define style of nodes or links',
          DisplayWelcome: 'Display welcome message',
          doc: 'Open the documentation',
          support: 'Contact the support',
          tuto: 'Explore tutorials',
          LegBgOpacity: 'Choose background color',
          LegBgBorder: 'Add a border to the legend area',
          LegBgColor: 'Choose background color opacity',
          old_app: 'Redirect to the legacy version of OpenSankey',
        },
      },
      MEP: {
        onBlur: 'Value is applied when we leave the input or we press enter',
        Echelle: 'Scale',
        leg_layout: 'Layout',
        leg_layout_text: 'Text',
        leg_layout_background: 'Background',
        leg_pos: 'size & position',
        leg_info: 'Informations',
        leg_show_dataTags: 'DataTags',
        leg_show_info_link_void: 'Show info concerning null link',

        links_size: 'Links size',
        MaxFlux: 'Max',
        MinFlux: 'Min',
        link_size_limit: 'Link size limit',
        TCG: 'Grid',
        TCG_shift: 'Shift',

        SLIP: 'Legend',
        AN: 'Arrange the nodes on the grid',
        Horizontal: 'Horizontal spacing between nodes',
        Vertical: 'Vertical spacing between nodes',
        PA: 'Automatic positioning of nodes and links',
        PA_action: 'Apply',
        factExpH: 'Horizontal expansion factor',
        factExpV: 'Vertical expansion factor',
        stretchH: 'Apply',
        stretchV: 'Apply',
        reInitDY: 'Réinitialiser',
        columnsParameters: 'Paramètres des colonnes',
        positioningMode: 'Mode de positionnement',
        parametricMode: 'Intervalles verticaux constants',
        absoluteMode: 'Points d\'ancrages fixes',
        importExport: 'Noeuds imports exports',
        importExportClose: 'Près du noeud',
        importExportAboveBelow: 'Haut et bas du diagramme',
        show_legend_free_value: 'Value is contained in an interval',
        legend_dashed_links: 'Unknown flow value',
        hide_leg: 'Hide the legend',
        show_leg: 'Show the legend',
        tooltips: {
          Echelle: 'Change the scale of the links and nodes',
          MaxFlux: 'Change the maximum size (in pixels) for the display of the links',
          MinFlux: 'Change the minimum size (in pixels) for the display of the links',
          TCG: 'Size of the background grid tiles (in pixels)',
          SLIP: 'Enable/Disable the presence of the legend when palette of color from elements tags are displayed',
          GV: 'Enable or disable the display of the background grid',
          AN: 'Automatically arrange nodes: ie come and paste the upper left corner of the nodes on the nearest grid corner',
          EEN_h: 'Default horizontal distance (in pixel) between nodes for automatic positioning',
          EEN_v: 'Default vertical distance (in pixel) between nodes for automatic positioning',
          PA: 'Perform an automatic positioning of the nodes according to the specified default distances',
          BgC: 'Change the background color of the interactive graphic space',
          factExpH: 'Modifies the horizontal expansion factor: the application of the factor multplie the horizontal deviation of each node from the leftmost by the input factor',
          factExpV: 'Modifies the vertical expansion factor: the application of the factor multplie the vertical deviation of each node from the highest by the input factor',
          reInitDY: 'Réinitialiser',
          columnsParameters: 'Paramètres des colonnes',
          positioningMode: 'Mode de positionnement',
          parametricMode: 'Intervalles verticaux constants',
          absoluteMode: 'Points d\'ancrages fixes',
          importExport: 'Noeuds imports exports',
          importExportClose: 'Près du noeud',
          importExportAboveBelow: 'Haut et bas du diagramme',
        }
      },
      Tags: {
        Nom: 'Name',
        Leg: 'Legend',
        tags: 'Tags',
        Bannière: 'Banner',
        Position: 'Position',
        Unique: 'Unique',
        Multiple: 'Multiple',
        Niveau: 'Level',
        Visible: 'Visible',
        Couleur: 'Color',
        Forme: 'Shape',
        GE: 'Tags\' Group',
        selct: 'Selected',
        tooltips: {
          pal: 'Apply a random color palette for the tags of the selected group',
          pal_shuffle: 'Change the order of the colors applied to the tags of the selected group',
          pal_std: 'Choose a standard color palette for the tags of the selected group',
          add: 'Add a tag to the selected group',
          add_grp: 'Add a tag group',
          rm: 'Delete the tag',
          rm_grp: 'Delete this tag group',
          nom: 'Rename the current tag',
          nom_grp: 'Rename this tag group',
          leg_grp: 'Activate or not the legend on the filtering of this group of tags and therefore the colors associated with the tags of this group',
          visible: 'Choose to make visible or not the nodes associated with this tag',
          couleur: 'Change the color of the current tag',
          forme: 'Choose the shape of the nodes associated with this tag',
          banner: 'Choose the type of dropdown menu on the filtering of this tag group (None / Single / Multiple / LevelTags)',
          up: 'Move the tag group up in the node filtering menu',
          down: 'Move the tag group down in the node filter menu'
        }
      },
      Noeud: {
        Nom: 'Name',
        Style: 'Style',
        multi_style: 'Selected nodes have different style',
        editStyle: 'Assign a style',
        context_agregate: 'Agregate',
        context_desagregate: 'Desagregate',
        AS: 'Reset',
        SelectStyle: 'Select a style',
        TS: 'Select all',
        NS: 'No node selected',
        Reorg: 'Reorganize incoming/outgoing links',
        Reorg_title: 'Reorganization',
        Slct: 'Selection',
        SlctOutLink: 'Select outgoing links',
        SlctInLink: 'Select incoming links',

        SlctL: 'Sélectionner les flux',
        SlctOL: 'Output',
        SlctIL: 'Input',
        IB: 'Tooltip',
        IS: 'Additional info.',
        align_horiz: 'Horizontally',
        align_horiz_min: 'Relative to the selected node furthest to the left',
        align_horiz_max: 'Relative to the selected node furthest to the right',
        align_vert: 'Vertically',
        align_vert_min: 'Relative to the selected topmost node',
        align_vert_max: 'Relative to the selected node furthest down',
        align_horiz_left: 'Align to its left',
        align_horiz_center: 'Align to its center',
        align_horiz_right: 'Align to its right',
        align_vert_top: 'Align to its top',
        align_vert_bottom: 'Align to its bottom',
        align: 'Align nodes',
        product: 'Product',
        sector: 'Sector',
        exchange: 'Exchange',
        size: 'Size',
        position: 'Position',
        text: 'Text',
        selector: 'Select nodes',
        title_desaggreg: 'Dimension disagregation',
        title_aggreg: 'Dimension agregation',
        text_agreg: 'Will agregate in : ',
        text_desagreg: 'Will disagregate in : ',
        desaggreg: 'Disagregation',
        aggreg: 'Agregation',
        tooltips: {
          Nom: 'Change the name of the selected node. If several nodes are selected, this field is disabled',
          AS: 'Allow to apply a predefined style in the preference menu to the selected node(s)',
          Reorg: 'Allows automatic reorganization of incoming and outgoing links (up/down position)',
          SlctOutLink: 'Allow to select all outgoing links from the node(s)',
          SlctInLink: 'Allow to select all incoming links to the node(s)',
          IB: 'Tooltip : text formatted in HTML and displayed with the tooltip of the node. This tooltip pops on graphic space with SHIFT + MOUSE on the node.',
        },
        drawing_area_tooltip: {
          outputs: 'Outputs',
          inputs: 'Inputs',
          dest: 'Destination',
          val: 'Values',
          rat: 'Ratios',
          prov: 'Origin',

        },
        tabs: {
          apparence: 'Appearence',
          infos: 'Additional info.',
          tags: 'Tags',
          io: 'I/O links positions',
        },
        apparence: {
          apparence: 'Appearence',
          Visibilité: 'Shape',
          Couleur: 'Color',
          CouleurPérenne: 'Static color',
          Forme: 'Geometry',
          Cercle: 'Circle',
          Rectangle: 'Rectangle',
          TML: 'Minimum width',
          TMH: 'Minimum height',
          geometry: 'Mode',
          geometry_absolute: 'Position',
          geometry_relative: 'Décalage',
          geometry_parametric: 'Ecartement',
          geometry_relative_dx: 'Décalage horizontal',
          geometry_relative_dy: 'Décalage vertical',
          geometry_u: 'Colonne',
          geometry_dx: 'Ecart horizontal',
          geometry_dy: 'Ecart vertical',
          asn: 'Apply style to nodes',
          override_type_node_shape: 'Override default shape',
          arrow: 'Arrow',
          arrow_angle: 'Angle',
          angle_orientation: 'Angle orientation',
          display_shape: 'Display the node shape',
          hide_shape: 'Hide the node shape',
          display_label: 'Display the label',
          hide_label: 'Hide the label',
          display_value: 'Display the value',
          hide_value: 'Hide the value',
          tooltips: {
            Visibilité: 'Makes the selected node(s) visible or invisible',
            Couleur: 'Choose the color of the selected node(s)',
            CouleurPérenne: 'Keep the color of the node(s) if any flux or data filter is activated, otherwise node turns grey (default color)',
            Forme: 'Choose a shape between rectangle or circle for the selected node(s)',
            FormeDisabled: 'Deactivated because the shape is managed by the "Type de noeud" tag group, if you want to be able to change the shape of the selected nodes, go to the "tags" tab and uncheck all the selected tag of the "Type de noeud" group',
            TML: 'Minimum width in pixels of the selected node(s)',
            TMH: 'Minimum height in pixels of the selected node(s)',
            geometry: 'Méthode pour calculer la position du noeud. Cette position peut être donnée en coordonnées absolues x,y, en coordonnée relative u,v, ou en coordonnée relative à une autre noeud.',
            geometry_relative_dx: 'Décalage horizontal par rapport au noeud de référence',
            geometry_relative_dy: 'Décalage vertical par rapport au noeud de référence',
            geometry_dx: 'Ecart horizontal entre le bord droit du noeud à gauche et le bord gauche du noeud',
            geometry_dy: 'Ecart vertical entre le bord inférieur du noeud au dessus et le bord supérieur du noeud',
            override_type_node_shape: 'Override the default forced shape of product and sector type nodes',
            arrow_angle: 'Change the angle of node arrow',
            angle_orientation: 'Change the orientation of the node',
          }
        },
        node_value: {
          anchor: 'Anchor position',
          anchor_dx: 'Horizontal shift',
          anchor_dy: 'Vertical shift',
          tooltips: {
            anchor: 'The anchor of node value can be positioned by combining horizontal position : left, middle and right; and vertically : top, middle and bottom',
            anchor_dx: 'Horizontal shift from label anchor',
            anchor_dy: 'Vertical shift from label anchor',
          }
        },
        labels: {
          labels: 'Name',
          node_value: 'Value',
          vdb: 'Label',
          lb: 'White label',
          l_bg: 'Background',
          pos: 'Position',
          pos_v: 'Value position',
          tp: 'Font size',
          police: 'Font type',
          cl: 'Text box width',
          vdv: 'Value',
          edit_node_label: 'Edit node label',
          anchor: 'Anchor position',
          anchor_dx: 'Horizontal shift',
          anchor_dy: 'Vertical shift',
          tooltips: {
            vdb: 'Displays or not the label(s) attached to the selected node(s)',
            lb: 'Displays the text of the label(s) in white or black (useful if label is positioned on node(s))',
            l_bg: 'Add a background to the label for a better visibility in case the label is in front of a link',
            top: 'Displays the label(s) above the selected node(s)',
            Milieu_pv: 'Displays the label(s) in the middle (vertically) of the selected node(s)',
            Bas: 'Displays the label(s) below the selected node(s)',
            gauche: 'Displays the label(s) to the left of the selected node(s)',
            Milieu_ph: 'Displays the label(s) in the middle (horizontally) of the selected node(s)',
            droite: 'Displays the label(s) to the right of the selected node(s)',
            cl: 'Width of the text area (in pixels) for the label(s) of the selected node(s)',
            vdv: 'Displays or not the associated value with the selected node(s)',
            anchor: 'L\'ancre du label peut être positionné de 9 manières par rapport à la forme combinant horizontalement bord gauche, milieu bord droit et verticalement bord supérieur milieu bord inférieur',
            anchor_dx: 'Horizontal shift from label anchor',
            anchor_dy: 'Vertical shift from label anchor',
            haut_val: 'Displays the associated value above the selected node(s)',
            Milieu_pv_val: 'Displays the associated value in the middle (vertically) of the selected node(s)',
            Bas_val: 'Displays the associated value below the selected node(s)',
            gauche_val: 'Displays the associated value to the left of the selected node(s)',
            Milieu_ph_val: 'Displays the associated value in the middle (horizontally) of the selected node(s)',
            droite_val: 'Displays the associated value to the right of the selected node(s)',
          }
        },
        tags_node: {
          tags: 'Tags',
          Appartenance: 'Affiliations'
        },
        agre: {
          Agré: 'Aggregation',
          DC: 'Cube\'s dimensions',
          Parent: 'Parent',
          CLE: 'Copy childs\' links'
        },
        PF: {
          PF: 'I/O links positions',
          PFM: 'I/O links positions',
          FES: 'Links type',
          ent: 'Input',
          sort: 'Output',
          FRN: 'Relative position / node',
          gauche: 'Left',
          droite: 'Right',
          ades: 'Above',
          edes: 'Below',
          lti: 'Identify the links in the table with their colors',
          col: 'Apply',
          tooltips: {
            io: 'Choose either the incoming or outgoing links of the selected node',
            side: 'Choose either the right, left, top or bottom links of the selected node',
            lti: 'Display the lines of the links table with their respective colors to better identify them'
          }
        }
      },
      Flux: {
        pg: 'General settings',
        pdl: 'Font',
        pl: 'Parameters per link',
        src: 'Source',
        trgt: 'Target',
        if: 'Reverse direction',
        dzf: 'Overlaying order',
        style: 'Style',
        as: 'Apply the style to selected link(s)',
        IB: 'ToolTip',
        IS: 'Additional info.',
        ajust_label: 'Adjust labels position',
        FS: 'Link',
        local_scale: 'Local link scale',
        tooltips: {
          src: 'Choice of the departure node for the selected link(s)',
          trgt: 'Choice of the arrival node for the selected link(s)',
          if: 'Reverse the start and finish nodes for the selected link(s)',
          up: 'Move up one step to the foreground',
          upup: 'Put the selected stream(s) in the foreground',
          dwn: 'Go down one step to the last plane',
          dwndwn: 'Move the selected stream(s) to the last plane',
          as: 'Allow to apply a predefined style in the preference menu to the selected link(s)',
          IB: 'Tooltip : text formatted in HTML and displayed with the tooltip of the link. This tooltip pops on graphic space with SHIFT + MOUSE on the link.',
          ajust_label: 'Reposition links labels above the links if they\'re taller than link stroke',
          inv: 'Inverse the target and the source'
        },
        data: {
          données: 'Data',
          vpp: 'Value',
          edit_value: 'Edit value',
          affichage: 'Display',
          tooltips: {
            vpp: 'Data associated to the selected link and, if existing, for the selected tag(s)',
            scientificNotation: 'Activate or not the notation in scientific format, for the display of the link\'s data on the Sankey diagram',
            affichage: 'Replacement text for the display of the link\'s data on the Sankey diagram'
          }
        },
        apparence: {
          apparence: 'Appearance',
          couleur: 'Color',
          grad: 'Gradient',
          hach: 'Dashed',
          of: 'Orientation',
          type: 'Type',
          courbe: 'Curve',
          fleche: 'Arrow',
          structure: 'Structure',
          opacity: 'Opacity',
          recy: 'Recycling',
          starting_curve: 'Starting point for curves',
          ending_curve: 'Ending point for curves',
          starting_tangeant: 'Starting curve radius',
          ending_tangeant: 'Ending curve radius',
          arrow_size: 'Size of arrow',
          display_link_lab: 'Display value',
          hide_link_lab: 'Hide value',
          data_off_scale: 'Scale',
          tooltips: {
            couleur: 'Choose the color for the selected link(s)',
            grad: 'Apply a gradient color effect on the selected link(s). The gradient is done between the color of the start node and the color of the end node',
            hach: 'Applies a hatch effect on the selected link(s)',
            of_vv: 'Allows to orient the beginning of the link vertically and the end of the link vertically',
            of_hh: 'Allows to orient the beginning of the link horizontally and the end of the link horizontally',
            of_hv: 'Allows to orient the beginning of the link horizontally and the end of the link vertically',
            of_vh: 'Allows to orient the beginning of the link vertically and the end of the link horizontally',
            courbe: 'Represents the selected link(s) as a Bezier curve',
            fleche: 'Represents the selected link(s) with an arrow tip at the end',
            structure: 'Represents the selected link(s) as if they didn\'t have values no matter their true value',
            recy: 'Represents the selected link(s) as a recycle, i.e. with a backward turn',
            starting_curve: 'Allows to refine the position of the starting point of curvature for the selected link(s). This value is a ratio (%) of the link\'s lenght relative to the starting point of the link.',
            ending_curve: 'Allows to refine the position of the ending point of curvature for the selected link(s). This value is a ratio (%) of the link\'s lenght relative to the starting point of the link.',
            starting_tangeant: 'Setting the radius of the starting curvature in case the selected link(s) are represented as Bezier curve(s)',
            ending_tangeant: 'Setting the radius of the ending curvature in case the selected link(s) are represented as Bezier curve(s)',
            arrow_size: 'Change the size of the arrow (from the end of the link to the node)',
            data_off_scale: 'Define a scale for this flow, wich can break the coherence of flow thickness proportionality of value '
          }
        },
        label: {
          label: 'Label',
          vdb: 'Label value',
          len: 'Black label',
          lb: 'White label',
          lec: 'Colored label',
          acf: 'Orient along the link axis',
          pos: 'Position',
          milieu: 'Middle',
          deb: 'Start',
          fin: 'End',
          dessous: 'Above',
          dessus: 'Below',
          pls: 'Manual positioning of the label',
          scientificNotation: 'Scientific notation',
          significantDigits: 'Significant digits',
          custom_digit: 'Decimals',
          NbDigit: 'Decimals',
          l_u_v: 'Unit',
          l_u: 'Unit\'s name',
          unit_factor: 'Unit factor',
          tooltips: {
            label: 'Display or not the label (data / text) associated to the selected link(s)',
            len: 'Display the label in black, for the selected stream(s)',
            lb: 'Display the label in white, for the selected stream(s)',
            lec: 'Display the label with the same color(s) as the one(s) associated with the selected stream(s)',
            acf: 'For the selected stream(s), allows to orient the label following the shape of the associated stream',
            deb: 'For the selected link(s), allows to position the label towards the starting point of the link',
            milieu_h: 'For the selected link(s), allows to position the label between the starting point and the end point of the link',
            end: 'For the selected stream(s), allows to position the label towards the end point of the stream',
            below: 'For the selected stream(s), allows to position the label below the stream',
            milieu_v: 'For the selected link(s), allows to position the label inside the link',
            top: 'For the selected stream(s), allows to position the label above the stream',
            pls: 'Combination Alt/left click move the label of the selected stream(s)',
            scientificNotation: 'Activate or not the notation in scientific format, for the display of the link\'s data on the Sankey diagram',
            significantDigits: 'Maximum number of significant digits',
            custom_digit: 'Maximum number of decimals',
            NbDigit: 'Maximum number of decimals',
            l_u_v: 'Display the unit of the links',
            l_u: 'Choose the name of the unit',
            unit_factor: 'Factor of conversion for link unity'
          }
        },
        layout: 'Disposition',
        layoutUp: 'Move forward',
        layoutTop: 'Move to the top',
        layoutDown: 'Move backward',
        layoutBottom: 'Move to the bottom',
      },

      Banner: {
        data: 'Data',
        filter: 'Filter',
        fdf: 'Link filtering',
        fdn: 'Node Filtering',
        ndd: 'Levels of detail',
        ndd_lst: 'Tags groups',
        ndd_chk: 'Apply the groups\' colors',
        sdr: 'Data type',
        sdd: 'Data selection',
        tl: 'Download',
        rslt: 'Download results',
        p_aff: 'Display settings',
        p_aff_aff_links: 'Links display settings',
        p_aff_filtre_links: 'Links display filter',
        type_value: 'Links value type',
        t_v_s: 'Structur',
        t_v_c: 'Collected data',
        t_v_r: 'Reconciled data',
        t_v_i: 'Interaval',
        t_v_pv: 'Possible values',
        indetermined_value: 'Links indetermined value type',
        filtre: 'Threshold value for links dislay',
        fl: 'Threshold value for labels display',
        fn: 'Null Link Visible',
        visible: 'Visible',
        hlp_1: 'Sankey diagram',
        hlp_1_txt_2: 'Choose the displayed level of aggregation for all the diagram\'s nodes',
        hlp_1_txt_3: 'Filters can be used to display only parts of the diagram. To do this use the selectors',
        hlp_1_txt_4: 'Different color palettes can be used to color nodes and links using the Color Palette selector',
        hlp_1_txt_5: 'The diagram structure (without link thickness) can be displayed by checking Diagram structure',
        hlp_1_txt_6: 'The diagram can be adjusted on screen by checking Adjust on screen',
        hlp_1_txt_7: 'To get information about each link, press shift and move the mouse over the link',
        hlp_1_txt_8: 'Links visualisations settings',
        hlp_1_txt_9: 'Dropdown to filter node/link/data according to their group tag or to choose to display the diagram with color palette from a tag group.',
        tooltipAdjustH: 'Adjust horizontaly drawing area to the screen size',
        tooltipAdjustV: 'Adjust verticaly drawing area to the screen size',
        tooltipStructure: 'Visualisation settings for the diagram\'s structure',
        tooltipSelection: 'Allows to drag an element',
        tooltipHelp: 'Show the help dialog',
        tooltipAjoutNode: 'Adds a node to the mouse click',
        tooltipLiason: 'Create node(s) or link(s)',
        select_sibling: 'Select siblings',
        fullscreen: 'Put the window in fullscreen',
        quit_fullscreen: 'Exit the fullscreen',
        hlp_node_tag_filter: 'Filter nodes and apply color of tagds',
        hlp_link_tag_filter: 'Filter link and apply color of tagds',
        hlp_data_tag_filter: 'Choose data and apply color of tagds',
      },
      welcome: {
        welcome: 'Welcome to OpenSankey web application',
        breadcrumbs: {
          intro: 'Overview',
          interface: 'Interface',
          rc: 'Shortcuts',
          licence: 'Licences',
        },
        read_me: 'Read me',
        intro: 'Quick overview of the application\'s functionalities',
        caroussel: {
          Image1: 'Understand your flows, represent them with Sankey diagrams',
          Image2: 'Quickly import your data or draw your diagrams directly',
          Image3: 'Clarify the information represented',
          Image40: 'Give the necessary depth of understanding',
          Image41: 'Give the necessary depth to understanding',
          Image5: 'Create interactive & didactic infographics',
          Image6: 'Create interactive & didactic infographics',
          descr: {
            Image1: 'In this representation mode, the thickness of each arrow is proportional to the value of the flow it represents',
            Image2: 'Create your diagrams from Excel spreadsheets or via the interactive drawing area',
            Image3: 'Make your diagrams easy to read with integrated node, flow and data labeling',
            Image40: 'Aggregation levels allow you to represent your flows in several levels of detail',
            Image41: 'Each level of detail can be directly selected to display only what is useful',
            Image5: 'Explain your achievements simply with the automatic legend system and the addition of text boxes',
            Image6: 'Create beautiful diagrams by directly integrating images or icons'
          }
        },
        interface: 'Interface\'s buttons explained',
        rc: 'Keyboard Shortcuts',
        licence: 'Licences',
        1: 'Mouse mode selection button, there are 2 modes: a mode to select nodes and links to be able to move them, then a mode to create links and nodes with mouse click',
        2: 'Buttons to filter the visibility or colors of nodes and links according to the groups to which they are associated',
        3: 'Buttons to filter the nodes according to their aggregation level',
        4: 'Buttons to filter the visibility of the links according to their values, this button also allows to modify the scale of the diagram (the thickness of the links)',
        5: 'Buttons to adjust the size of the drawing area so that all elements are visible',
        6: 'Buttons to display the diagram without taking into account the value of the links',
        7: 'Buttons to display additional help',
        8: 'Navigation menu to open,save, edit sankeys',
        9: 'Buttons to connect with an opensankey account and enable additional modules',
        10: 'Buttons to open the configuration menu to edit the elements of the sankey diagram',
        excel: 'Button download the data used for the sankey in Excel format'
      },
      useTemplate: 'Use this template',
      DisplayWelcome: 'Welcome',
      dontSeeAgain: 'Don\'t show again',
      scale: 'Scale',
      useTutoJSON: 'Open',
      useTutoExcel: 'Open Excel',
      menuTuto: 'Open tutorials menu',
      desire_to_know_more: 'Learn more',
      contribute_to_os: 'En savoir +',
      legal: 'Legal information',
      tdr: 'All right reserved',
      fullscreen: 'Activate fullscreen',
      exitFullscreen: 'Exit fullscreen',
      Avancé: 'Advanced',
      separator_interval: ',',
      sep_decimal: '.',
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      Menu: {
        presentation_OS: 'OpenSankey et une application web qui permet de réaliser simplement des diagrammes de Sankey.\n\nCelle-ci est accessible gratuitement, et inclut les fonctionnalités simples de création de nœuds, de flux et leur étiquetage afin d\'agréger ou de filtrer leur affichage sur le diagramme.\n\n',
        presentation_OS_limit_node: 'La création d\'un compte gratuit permet de lever la limitation de 15 nœuds fixée pour un usage sans compte.\n\n',
        Aucun: 'Aucun',
        confSankey: 'Configuration du Sankey',
        MEP: 'Zone de dessin',
        background: 'Arrière-plan',
        Noeuds: 'Noeuds',
        EN: 'Étiquettes des noeuds',
        EdN: 'Édition des noeuds',
        flux: 'Flux',
        EF: 'Étiquettes des flux',
        EdF: 'Édition des flux',
        ED: 'Étiquettes des données',
        Leg: 'Légende',
        LegX: 'Position horizontale',
        LegY: 'Position verticale',
        LegWidth: 'Largeur',
        larg: 'Largeur',
        haut: 'Hauteur',
        Fichiers: 'Fichiers',
        edition: 'Mise en forme',
        formation: 'Tutoriels',
        Exemples: 'Sankeythèque',
        Aide: 'Aide',
        ouvrir: 'Ouvrir',
        enregistrer: 'Enregis.',
        new: 'Nouv.',
        exporter: 'Exporter',
        exporter_to: 'En',
        preference: 'Réglages',
        reinit: 'Réinit.',
        Elements: 'Éléments',
        Etiquettes: 'Étiquettes',
        amp_import: 'Depuis autre diagramme',
        amp_short: 'Trans.',
        ajustH: 'Ajust. H',
        ajustV: 'Ajust. V',
        esn: 'Édition style de noeuds',
        esf: 'Édition style de flux',
        rc: 'Raccourcis clavier',
        as: 'Aide Supplémentaire',
        doc: 'Doc.',
        ca: 'Chemin d\'accés',
        annuler: 'Annuler',
        submit: 'Ok',
        pdd: 'Publier/Mettre à jour le diagramme',
        fmep: 'Autre diagramme',
        ad: 'Appliquer la transformation',
        ns: 'Nom du Style',
        BgC: 'Couleur',
        dns: 'N\'affiche que les noeuds visibles dans le sélecteur de noeuds',
        dls: 'N\'affiche que les flux visibles dans le sélecteur de flux',
        Tags: 'Etiquettes',
        Values: 'Valeurs',
        topoNode: 'des Noeuds',
        topoFlux: 'des Flux',
        tagLevel: 'Niveaux de détail',
        tagNode: 'Etiquette Noeuds',
        tagFlux: 'Etiquette Flux',
        tagData: 'Etiquette Données',
        tagNode_assign: 'Assigner une étiquette',
        tagFlux_assign: 'Assigner une étiquette',
        SaveJSON: 'Enregistrer JSON',
        SaveValue: 'Enregistrer avec les valeurs des flux',
        VisibleElement: 'Enregistrer que les éléments visibles',
        templates: 'Modèles',
        warningLimitNode: 'Attention :Nombre de noeuds limite atteint pour les utilisateurs non connecté',
        licence: 'Licences',
        display_scale: 'Échelle',
        fontSize: 'Taille police',
        file: 'Fichiers',
        demo: 'Sankeythèque',
        aide: 'Aide',
        filter: 'Filtres',
        toolbar: 'Outils',
        check: 'Sauv.',
        last_save: 'Dernière sauvegarde',
        style: 'Styles',
        close: 'Fermer',
        rcc_titre_princ: 'Raccourcis de l\'application OpenSankey',
        rcc_titre_select: 'Avec la souris en mode sélection',
        rcc_cn_bold: 'Click (noeuds)',
        rcc_cn: 'Sélectionne uniquement le noeud cliqué',
        rcc_shift_cn_bold: 'Shift + Click (noeuds)',
        rcc_shift_cn: 'Sélectionne le noeud cliqué et ouvre l\'onglet "Noeuds" du menu',
        rcc_ctrl_cn_bold: 'CTRL + Click (noeuds)',
        rcc_ctrl_cn: 'Ajoute le noeud cliqué à la liste des noeuds sélectionnés',
        rcc_cf_bold: 'Click (flux)',
        rcc_cf: 'Sélectionne le flux cliqué',
        rcc_shift_cf_bold: 'Shift + Click (flux)',
        rcc_shift_cf: 'Sélectionne le flux cliqué et ouvre l\'onglet "Flux" du menu',
        rcc_ctrl_cf_bold: 'CTRL + Click (flux)',
        rcc_ctrl_cf: 'Ajoute le flux cliqué à la liste des flux sélectionnés',
        rcc_cs_bold: 'Click (en dehors d\'un noeud/flux)',
        rcc_cs: 'Désélectionne les noeuds et flux sélectionnés',
        rcc_click_and_drag_bold: 'Click & drag  (zone de dessin)',
        rcc_click_and_drag: 'Crée une zone de sélection qui permet de sélectionner les noeuds compris dans cette zone une fois que le relâche la souris',
        rcc_cdn_bold: 'Click droit (noeuds, flux, zone de dessin)',
        rcc_cdn: 'Ouvre un menu contextuel permettant de faire des modifications rapides des éléments',
        rcc_ad_bold: 'Alt Drag (label noeuds)',
        rcc_ad: 'Déplace le label',
        rcc_titre_edi: 'Avec la souris en mode édition',
        rcc_e_cn_bold: 'Click (zone de dessin)',
        rcc_e_cn: 'Ajoute un noeud à l\'endroit cliqué',
        rcc_e_ds_bold: 'Drag (à partir de la zone de dessin)',
        rcc_e_ds: 'Crée un noeud au point de départ du drag puis crée un flux à partir du noeud crée vers : soit un noeud déjà existant si l\'on drop dessus, soit un noeud que l\'on crée à l\'endroit où l\'on drop sur la zone de dessin',
        rcc_e_dn_bold: 'Drag (à partir d\'un noeud)',
        rcc_e_dn: 'Créer un flux à partir du noeud de départ du drag vers : soit un noeud déjà existant si l\'on drop dessus, soit un noeud que l\'on crée à l\'endroit où l\'on drop sur la zone de dessin',
        rcc_titre_autre: 'Autres raccourcis',
        rcc_a_s_bold: 'Suppr',
        rcc_a_s: 'Supprime les noeuds et flux sélectionnés',
        rcc_a_fc_bold: 'Flèche du clavier',
        rcc_a_fc: 'Permet de déplacer les noeuds sélectionnés en fonction du grillage',
        rcc_a_dbm_bold: 'Drag (bouton du milieu de la souris et en dehors d\'un noeud/flux):',
        rcc_a_dbm: ' Permet de déplacer le sankey complet',
        rcc_a_ech_bold: 'Echap',
        rcc_a_ech: 'Ferme le Menu quand il est ouvert et remet la fonction de la souris en tant que sélecteur',
        rcc_titre_OSP: 'Raccourcis du module OpenSankey+',
        rcc_osp_cs_bold: 'CTRL+X',
        rcc_osp_cs: 'Crée une vue qui peut être ensuite modifié, pour conserver les modifications appliquées à la vue il faut l\'enregistrer avec Ctrl+S',
        rcc_osp_ctrl_czdt_bold: 'CTRL+Click (Zone Texte) :',
        rcc_osp_ctrl_czdt: 'Sélectionne la zone clické et ouvre le menu d\'édition de zone de texte',
        rcc_F7_bold: 'F7',
        rcc_F7: 'Retourne aux données maîtres',
        rcc_F8_bold: 'F8',
        rcc_F8: 'Passe à la vue précédente',
        rcc_F9_bold: 'F9',
        rcc_F9: 'Passe à la vue suivante',
        rcc_ctrl_scrll_bold: 'CTRL+scroll (zone de dessin)',
        rcc_ctrl_scrll: 'Permet de zoomer/dezomer sur la zone de dessin',
        support: 'Contact',
        c_support: 'Contacter le support',
        rth_support: 'Nous sommes là pour vous aider',
        support_explication: 'Si vous rencontrez un problème technique, écrivez-nous à [], nous traiterons votre demande dans les meilleurs délais.',
        suppr: 'Supprimer',
        rand_node_color: 'Assigner une couleur aléatoire à tous les noeuds',
        load_file: 'Lecture du fichier',
        compute_file: 'Traitement des informations',
        loaded_file: 'Chargement terminé',
        failure_file: 'Chargement échoué',
        choseTransforDifficulty: 'Types d\'éléments',
        selectNodeAttrubutedToTag: 'Sélectionne les noeuds possédant cette étiquette',
        selectLinkAttrubutedToTag: 'Sélectionne les flux possédant cette étiquette',
        node_label_sep: 'Caractère(s) limitant les noms des noeuds affichés',
        node_label_sep_pos: 'Sélectionner la partie des labels à afficher',
        before: 'Avant',
        after: 'Après',
        from_new: 'Diagramme vierge',
        from_model: 'A partir d\'un modèle',
        open_json: 'Diagramme de Sankey (fichier JSON)',
        open_excel: 'Données (fichier Excel)',
        open_sankeymatic: 'SankeyMATIC (fichier text)',
        title_pref: 'Paramétrage de l\'application',
        lang: 'Langue',
        pref_title_sub_menu: 'Choix des sous-menus visibles dans l\'interface',
        overcharge_style_value: 'La valeur de la variable surcharge celle du style',
        group: 'Groupe d\'étiquettes',
        color: 'Couleur',
        oher_file: 'Autre fichier',
        LegBgOpacity: 'Opacité',
        LegBgBorder: 'Bordure',
        LegBgColor: 'Couleur',
        open_excel_file: 'Ouvrir fichier excel',
        input_file_excel: 'Fichier d\'entrée excel',
        no_input_file_detected: 'Aucun fichier n\'a été sélectionné',
        waiting: 'Veuillez patienter',
        old_app: 'Version préc. app.',

        Transformation: {
          amp_short: 'M.E.P',
          amp: 'Modifier la mise en page',
          amp_import: 'Depuis autre diagramme',
          amp_manuelle: 'Positionnement des noeuds',
          trans_topo: 'Topologiques',
          fmep: 'Diagramme source',
          ad: 'Appliquer',
          undo: 'Annuler',
          Shortcuts: 'Raccourcis sélections',
          unSelectAll: 'Aucun',
          selectAll: 'Tout',
          selectDefault: 'Défaut',
          Topology: 'Ajouts et suppressions',
          Geometry: 'Tailles et positions',
          Attribut: 'Attributs',
          Tags: 'Etiquettes',
          Values: 'Valeurs des flux',
          Views: 'Vues',
          freeLabels: 'Zones de texte',
          addNode: 'Aj. Noeuds',
          removeNode: 'Sup. Noeuds',
          addFlux: 'Aj. Flux',
          removeFlux: 'Sup. Flux',
          PosNoeud: 'Noeuds',
          posFlux: 'Flux',
          attrNode: 'Noeuds',
          attrFlux: 'Flux',
          tagLevel: 'Niveaux de détail',
          tagNode: 'Noeuds',
          tagFlux: 'Flux',
          tagData: 'Données',
          tagNode_assign: 'Assigner une étiquette',
          tagFlux_assign: 'Assigner une étiquette',
          attrGeneral: 'Zone de dessin',
          title: 'Mise en page',
          tooltips: {
            Shortcuts: 'Raccourcie de sélection des attributs à transférer du sankey importé',
            Topology: 'Transfert des noeud/flux ajoutés/supprimés du sankey importé vers l\'actuel',
            Geometry: 'Transfert les attributs de positionnement des noeuds et flux du sankey importé vers l\'actuel',
            Attribut: 'Transfert les attributs des noeuds et flux du sankey importé vers l\'actuel',
            Tags: 'Transfert les étiquettes de noeud et flux du sankey importé vers l\'actuel',
            Values: 'Transfert les valeurs des flux du sankey importé vers l\'actuel',
            tagLevel: 'Transfert les nieveaux de détails du sankey importé vers l\'actuel',
            attrGeneral: 'Transfert les attributs de la zone de dessin du sankey importé vers l\'actuel',
          }
        },
        tooltips: {
          noeud: {
            slct: 'Choisir un / des / tous les noeud(s) à sélectionner via une liste déroulante',
            plus: 'Ajouter un noeud. Celui-ci sera automatiquement selectionné.',
            rm: 'Permettre de supprimer tous les noeud(s) actuellement sélectionné(s)',
            dns: 'Afficher dans la liste de selection des noeuds, uniquement ceux actuellement visibles sur l\'espace graphique'
          },
          flux: {
            slct: 'Choisir un / des / tous les flux à sélectionner via une liste déroulante',
            plus: 'Ajouter un flux. Celui-ci sera automatiquement selectionné.',
            rm: 'Permettre de supprimer tous les flux actuellement sélectionné(s)',
            dls: 'Afficher dans la liste de selection des flux, uniquement ceux actuellement visibles sur l\'espace graphique',
          },
          LegX: 'Ecart horizontal (en px) entre le coin supérieur gauche de la légende par rapport au coin supérieur gauche de la fenêtre',
          LegY: 'Ecart vertical (en px) entre le coin supérieur gauche de la légende par rapport au coin supérieur gauche de la fenêtre',
          LegWidth: 'Largeur (en px) de l\'espace de la légende',
          BgC: 'Changer la couleur fond de l\'espace graphique interactif',
          checkpoint: 'Sauvegarde locale sur le navigateur (mémoire cache)',
          fontSize: 'Modifie la taille de la police pour la légende',
          node_label_sep: 'Chaine de caractère qui permettra de masquer une partie d\'un label de noeud, exemple : le séparateur est " - " et le nom du noeud est " noeud1 - sousinfo" alors le label du noeud affiché dans la zone de dessin sera "noeud1"',
          node_label_sep_pos: 'Choisir si l\'on affiche le label des noeuds qui ce situe avant ou après le séparateur',
          new: 'Créer un nouveau diagramme de Sankey',
          ouvrir: 'Ouvrir un fichier',
          enregistrer: 'Enregistrer sous',
          preference: 'Choisir les paramètres de l\'application',
          amp: 'Modifier la mise en page du diagramme',
          style: 'Définir des styles par groupe de noeuds ou de flux',
          DisplayWelcome: 'Afficher le message d\'accueil',
          doc: 'Ouvrir la documentation',
          support: 'Contacter le support',
          tuto: 'Explorer les tutoriels',
          LegBgOpacity: 'Modifie l\'opacité de la couleur de fond de la légende',
          LegBgBorder: 'Ajoute une bordure à la légende',
          LegBgColor: 'Moddifie la couleur de fond de la légende',
          old_app: 'Redirige vers la version précédente de OpenSankey',
        },
      },
      MEP: {
        onBlur: 'La valeur sera appliqué dès que l\'on quitte l\'input ou presser la touche entrée',
        Echelle: 'Echelle',
        links_size: 'Taille des flux',
        leg_layout: 'Mise en forme',
        leg_layout_text: 'Texte',
        leg_layout_background: 'Fond',
        leg_pos: 'Taille et position',
        leg_info: 'Informations',
        leg_show_dataTags: 'Etiquettes de données',
        leg_show_info_link_void: 'Affiche info concernant les flux null',

        MaxFlux: 'Max',
        MinFlux: 'Min',
        link_size_limit: 'Taille limite des flux',
        TCG: 'Quadrillage',
        TCG_shift: 'Espacement',
        SLIP: 'Légende',
        AN: 'Arranger les noeuds sur le quadrillage',
        Horizontal: 'Ecartement horizontal',
        Vertical: 'Ecartement vertical',
        PA: 'Positionnement automatique',
        PA_action: 'Appliquer',
        factExpH: 'Facteur d\'expansion horizontale',
        factExpV: 'Facteur d\'expansion verticale',
        stretchH: 'Appliquer',
        stretchV: 'Appliquer',
        reInitDY: 'Réinitialiser',
        columnsParameters: 'Paramètres des colonnes',
        positioningMode: 'Mode de positionnement',
        parametricMode: 'Intervalles verticaux constants',
        absoluteMode: 'Points d\'ancrages fixes',
        importExport: 'Noeuds imports exports',
        importExportClose: 'Près du noeud',
        importExportAboveBelow: 'Haut et bas du diagramme',
        defaultParametric: 'Calculer paramétrisation',
        show_legend_free_value: 'Valeur contenue dans un intervalle',
        legend_dashed_links: 'Valeur du flux inconnu',
        hide_leg: 'Masquer la légende',
        show_leg: 'Afficher la légende',
        tooltips: {
          Echelle: 'Modifier l\'échelle des flux et noeuds',
          MaxFlux: 'Modifier la taille maximale (en pixel) pour l\'affichage des flux',
          MinFlux: 'Modifier la taille minimale (en pixel) pour l\'affichage des flux',
          TCG: 'Taille des carreaux de la grille de fond (en pixel)',
          SLIP: 'Active/désactive l\'affichage de la légende quand les palettes de couleurs des étiquettes sont affichées',
          GV: 'Activer ou désactiver l\'affichage de la grille de fond',
          AN: 'Arrangement automatiquement les noeuds : ie venir coller le coin supérieur gauche des noeuds sur le coin de grille le plus proche',
          EEN_h: 'Ecart horizontal par défaut (en pixel) entre les noeuds pour un positionnement automatique',
          EEN_v: 'Ecart vertical par défaut (en pixel) entre les noeuds pour un positionnement automatique',
          PA: 'Réaliser un positionnement automatique des noeuds en fonction des écarts par défaut spécifiés',
          BgC: 'Changer la couleur fond de l\'espace graphique interactif',
          factExpH: 'Modifie le facteur d\'expansion horizontale : l\'application du facteur multplie l\'écart horizontale de chaque noeuds par rapport à celui le plus à gauche par le facteur de l\'input',
          factExpV: 'Modifie le facteur d\'expansion verticale : l\'application du facteur multplie l\'écart verticale de chaque noeuds par rapport à celui le plus haut par le facteur de l\'input',
          reInitDY: 'Réinitialiser',
          columnsParameters: 'Paramètres des colonnes',
          positioningMode: 'Mode de positionnement',
          parametricMode: 'Intervalles verticaux constants',
          absoluteMode: 'Points d\'ancrages fixes',
          importExport: 'Noeuds imports exports',
          importExportClose: 'Près du noeud',
          importExportAboveBelow: 'Haut et bas du diagramme',
        }
      },
      Tags: {
        Nom: 'Nom',
        Leg: 'Légende',
        tags: 'Étiquette',
        Bannière: 'Bannière',
        Position: 'Position',
        Unique: 'Unique',
        Multiple: 'Multiple',
        Niveau: 'Niveau',
        Visible: 'Visible',
        Couleur: 'Couleur',
        Forme: 'Forme',
        GE: 'Étiquettes du groupe',
        selct: 'Sélectionné',
        tooltips: {
          pal: 'Appliquer une palette de couleurs aléatoires pour les étiquettes du groupe sélectionné',
          pal_shuffle: 'Changer l\'ordre des couleur appliquées aux étiquettes du groupe sélectionné',
          pal_std: 'Choisir une palette de couleurs standard pour les étiquettes du groupe sélectionné',
          add: 'Ajouter une étiquette au groupe sélectionné',
          add_grp: 'Ajouter un groupe d\'étiquette',
          rm: 'Supprimer l\'étiquette',
          rm_grp: 'Supprimer ce groupe d\'étiquette',
          nom: 'Renommer l\'étiquette courante',
          nom_grp: 'Renommer ce groupe d\'étiquette',
          leg_grp: ' Active ou non la légende sur le filtrage de ce groupe d\'étiquettes et donc les couleurs associées aux étiquettes de ce groupe',
          visible: 'Choisir de rendre visible ou non les noeuds associés à cette étiquette',
          couleur: 'Changer la couleur de l\'étiquette courante',
          forme: 'Choisir la forme des noeuds associés à cette étiquette',
          banner: 'Choisir le type de menu déroulant sur le filtrage de ce groupe d\'étiquettes (Aucun / Unique / multiple / LevelTags)',
          up: 'Monter le groupe d\'étiquettes dans le menu de filtrage des noeuds',
          down: 'Descendre le groupe d\'étiquettes dans le menu de filtrage des noeuds'
        }
      },
      Noeud: {
        Nom: 'Nom',
        Style: 'Style',
        editStyle: 'Assigner un style',
        multi_style: 'Multiple style sélectionné',
        SelectStyle: 'Sélectionner un style',
        context_agregate: 'Agréger',
        context_desagregate: 'Désagréger',
        AS: 'Réinitialiser',
        TS: 'Tout sélectionner',
        NS: 'Aucun noeud sélectionné',
        Reorg: 'Réorganiser automatiquement les flux E/S',
        Reorg_title: 'Réorganisation',
        Slct: 'Sélection',
        SlctOutLink: 'Sélectionner flux sortants',
        SlctInLink: 'Sélectionner flux entrants',
        SlctL: 'Sélectionner les flux',
        SlctOL: 'Sortants',
        SlctIL: 'Entrants',
        IB: 'Info-bulle',
        IS: 'Info sup.',
        align_horiz: 'Horizontalement',
        align_horiz_min: 'Par rapport au noeud sélectionné le + à gauche',
        align_horiz_max: 'Par rapport au noeud sélectionné le + à droite',
        align_vert: 'Verticalement',
        align_vert_min: 'Par rapport au noeud sélectionné le + en haut',
        align_vert_max: 'Par rapport au noeud sélectionné le + en bas',
        align_horiz_left: 'À son côté gauche',
        align_horiz_center: 'À son centre',
        align_horiz_right: 'À son côté droit',
        align_vert_top: 'À son côté du dessus',
        align_vert_bottom: 'À son côté du dessous',
        align: 'Aligner les noeuds',
        product: 'Produit',
        sector: 'Secteur',
        exchange: 'Échange',
        selector: 'Selectionner des noeuds',
        size: 'Taille',
        position: 'Position',
        text: 'Texte',
        title_desaggreg: 'Dimension desagrégation',
        title_aggreg: 'Dimension agrégation',
        text_agreg: 'Sera aggrégé en : ',
        text_desagreg: 'Sera désaggrégé en : ',
        desaggreg: 'Désagrégation',
        aggreg: 'Agrégation',
        tooltips: {
          Nom: 'Changer le nom du noeud sélectionné. Si plusieurs noeuds sont sélectionnés, ce champ est désactivé',
          AS: 'Permettre d\'appliquer un style prédéfini dans le menu préférence au(x) noeud(s) selectionné(s)',
          Reorg: 'Permet de réorganiser automatiquement les flux entrant et sortant (position haut / bas)',
          SlctOutLink: 'Permettre de sélectionner tous les flux sortants du/des noeud(s)',
          SlctInLink: 'Permettre de sélectionner tous les flux entrants vers le/les noeud(s)',
          IB: 'Info bulle : texte formaté en HTML et affiché avec l\'info-bulle du noeud. Cet info-bulle apparait sur espace graphique avec SHIFT + SOURIS sur le noeud',
        },
        drawing_area_tooltip: {
          outputs: 'Sorties',
          inputs: 'Entrées',
          val: 'Valeurs',
          rat: 'Ratios',
          prov: 'Provenance',
          dest: 'Destination',
        },
        tabs: {
          apparence: 'Apparence',
          infos: 'Info sup.',
          tags: 'Étiquettes',
          io: 'Flux E/S',
        },
        apparence: {
          apparence: 'Apparence',
          Visibilité: 'Forme',
          Couleur: 'Couleur',
          CouleurPérenne: 'Couleur fixe',
          Forme: 'Géométrie',
          Cercle: 'Cercle',
          Rectangle: 'Rectangle',
          TML: 'Largeur minimale',
          TMH: 'Hauteur minimale',
          geometry: 'Mode',
          geometry_absolute: 'Position',
          geometry_relative: 'Décalage',
          geometry_parametric: 'Ecartement',
          geometry_relative_dx: 'Décalage horizontal',
          geometry_relative_dy: 'Décalage vertical',
          geometry_u: 'Colonne',
          geometry_dx: 'Ecart horizontal',
          geometry_dy: 'Ecart vertical',
          asn: 'Appliquer le style aux noeuds',
          override_type_node_shape: 'Déverrouiller la forme',
          arrow: 'Flèche',
          arrow_angle: 'Inclinaison flèche',
          angle_orientation: 'Orientation flèche',
          display_shape: 'Afficher le noeud',
          hide_shape: 'Masquer le noeud',
          display_label: 'Afficher le nom',
          hide_label: 'Masquer le nom',
          display_value: 'Afficher la valeur',
          hide_value: 'Masquer la valeur',
          tooltips: {
            Visibilité: 'Rend le/les noeud(s) selectionné(s) visible(s) ou invisible(s)',
            Couleur: 'Choisir la couleur du/des noeud(s) selectionné(s)',
            CouleurPérenne: 'Garder la couleur du/des noeud(s) en cas de filtrage des flux ou données. Sinon le noeud reste en gris (couleur par defaut).',
            Forme: 'Choisir une Forme entre rectangle ou cercle pour le/les noeud(s) selectionné(s)',
            FormeDisabled: 'Désactivé car la forme est gérée par le groupe d\'étiquette "Type de noeud", si vous voulez pouvoir modifier la forme des noeuds séléctionnés, allez dans l\'onlget Étiquettes et décochez tous les étiquettes sélectionné du groupe "Type de noeud"',
            TML: 'Largeur minimale en pixel du/des noeud(s) selectionné(s)',
            TMH: 'Hauteur minimale en pixel du/des noeud(s) selectionné(s)',
            geometry: 'Méthode pour calculer la position du noeud. Cette position peut être donnée en coordonnées absolues x,y, en coordonnée relative u,v, ou en coordonnée relative à une autre noeud.',
            geometry_relative_dx: 'Décalage horizontal par rapport au noeud de référence',
            geometry_relative_dy: 'Décalage vertical par rapport au noeud de référence',
            geometry_dx: 'Ecart horizontal entre le bord droit du noeud à gauche et le bord gauche du noeud',
            geometry_dy: 'Ecart vertical entre le bord inférieur du noeud au dessus et le bord supérieur du noeud',
            override_type_node_shape: 'Permet de changer la forme forcée par défaut sur les noeuds de types produits et secteur',
            arrow_angle: 'Change l\'angle du noeud en forme  de flèche',
            angle_orientation: 'Change l\'orientation du noeud en forme  de flèche',
          }
        },
        node_value: {
          anchor: 'Position de l\'ancre',
          anchor_dx: 'Décalage horizontal',
          anchor_dy: 'Décalage vertical',
          tooltips: {
            anchor: 'L\'ancre de la valeur du noeud peut être positionné de 9 manières par rapport à la forme combinant horizontalement bord gauche, milieu bord droit et verticalement bord supérieur milieu bord inférieur',
            anchor_dx: 'Décalage horizontal par rapport à l\'ancre de la valeur du noeud',
            anchor_dy: 'Décalage vertical par rapport à l\'ancre e la valeur du noeud',
          }
        },
        labels: {
          labels: 'Nom',
          node_value: 'Valeur',
          vdb: 'Label',
          lb: 'Blanc',
          l_bg: 'Fond',
          pos: 'Position',
          pos_v: 'Position de la valeur',
          tp: 'Taille de police',
          police: 'Style de police',
          cl: 'Largeur zone de texte',
          vdv: 'Valeur',
          edit_node_label: 'Éditer le nom',
          anchor: 'Position de l\'ancre',
          anchor_dx: 'Décalage horizontal',
          anchor_dy: 'Décalage vertical',
          tooltips: {
            vdb: 'Permet d\'afficher ou non le label accolé au(x) noeud(s) sélectionné(s)',
            lb: 'Permet d\'afficher le texte du label en blanc ou en noir (utile si label positionné sur noeud(s))',
            l_bg: 'Permet d\'ajouter un fond au label pour qu\'il soit plus visible quand il est par-dessus un flux',
            haut: 'Positionner le label au dessus du/des noeud(s) sélectionné(s)',
            Milieu_pv: 'Positionner le label au milieu (verticalement) du/des noeud(s) sélectionné(s)',
            Bas: 'Positionner le label en dessous du/des noeud(s) sélectionné(s)',
            gauche: 'Positionner le label à gauche du/des noeud(s) sélectionné(s)',
            Milieu_ph: 'Positionner le label au milieu (horizontalement) du/des noeud(s) sélectionné(s)',
            droite: 'Positionner le label à droite du/des noeud(s) sélectionné(s)',
            cl: 'Largeur de la zone de texte (en pixel) pour le label du/des noeud(s) sélectionné(s)',
            vdv: 'Active l\'affichage de la valeur associée au(x) noeud(s) sélectionné(s)',
            anchor: 'L\'ancre du label peut être positionné de 9 manières par rapport à la forme combinant horizontalement bord gauche, milieu bord droit et verticalement bord supérieur milieu bord inférieur',
            anchor_dx: 'Décalage horizontal par rapport à l\'ancre du label',
            anchor_dy: 'Décalage vertical par rapport à l\'ancre du label',
            haut_val: 'Positionner l\'affichage de la valeur associée au dessus du/des noeud(s) sélectionné(s)',
            Milieu_pv_val: 'Positionner l\'affichage de la valeur associée au milieu (verticalement) du/des noeud(s) sélectionné(s)',
            Bas_val: 'Positionner l\'affichage de la valeur associée en dessous du/des noeud(s) sélectionné(s)',
            gauche_val: 'Positionner l\'affichage de la valeur associée à gauche du/des noeud(s) sélectionné(s)',
            Milieu_ph_val: 'Positionner l\'affichage de la valeur associée au milieu (horizontalement) du/des noeud(s) sélectionné(s)',
            droite_val: 'Positionner l\'affichage de la valeur associée à droite du/des noeud(s) sélectionné(s)',
          }
        },

        tags_node: {
          tags: 'Étiquettes',
          Appartenance: 'Appartenance'
        },
        agre: {
          Agré: 'Agrégations',
          DC: 'Dimension du cube',
          Parent: 'Parent',
          CLE: 'Copier liens enfants'
        },
        PF: {
          PF: 'Flux E/S',
          PFM: 'Organiser flux E/S',
          FES: 'Type de flux',
          ent: 'Entrant',
          sort: 'Sortant',
          FRN: 'Position / noeud',
          gauche: 'Gauche',
          droite: 'Droite',
          ades: 'Au-dessus',
          edes: 'En-dessous',
          lti: 'Identifier les flux du tableau avec leurs couleurs',
          col: 'Appliquer',
          tooltips: {
            io: 'Choisir les flux entrants ou sortant du noeud selectionné',
            side: 'Choisir les flux de droite, gauche, bas ou haut du noeud selectionné',
            lti: 'Affiche les lignes du tableau des flux avec leur couleurs respectives pour mieux les identifier'
          }
        }
      },
      Flux: {
        pg: 'Paramètres généraux',
        pdl: 'Police des labels',
        pl: 'Paramètres par flux',
        src: 'Source',
        trgt: 'Cible',
        if: 'Inverser le sens',
        dzf: 'Superposition d\'affichage',
        style: 'Style',
        as: 'Réinitialiser',
        IB: 'Info-bulle',
        IS: 'Info sup.',
        ajust_label: 'Position vertical ajusté',
        local_scale: 'Échelle propre au flux',
        FS: 'Flux',
        tooltips: {
          src: 'Choix du noeud de départ pour le/les flux sélectionné(s)',
          trgt: 'Choix du noeud d\'arrivée pour le/les flux sélectionné(s)',
          if: 'Permet d\'inverser les noeuds de départ et d\'arrivée pour le/les flux sélectionné(s)',
          up: 'Monter d\'un cran vers le premier plan',
          upup: 'Mettre le/les flux selectionné(s) au premier plan',
          dwn: 'Descendre d\'un cran vers le dernier plan',
          dwndwn: 'Mettre le/les flux sélectionné(s) au dernier plan',
          as: 'Permettre d\'appliquer un style prédéfini dans le menu préférence au(x) flux selectionné(s)',
          IB: 'Info bulle : texte formaté en HTML et affiché avec l\'info-bulle du flux. Cet info-bulle apparait sur espace graphique avec SHIFT + SOURIS sur le flux',
          ajust_label: 'Repositionne les labels de flux au dessus si les labels sont plus grand que l\'épaisseur des flux',
          inv: 'Inverse le sens du flux en inversant sa source et sa cible'
        },
        data: {
          edit_value: 'Éditer la valeur',
          données: 'Données',
          vpp: 'Valeur',
          affichage: 'Affichage',
          tooltips: {
            vpp: 'Valeur associée au flux sélectionné et, si existant(s), pour le/les tag(s) de flux selectionné(s)',
            scientificNotation: 'Activer ou non la notation en format scientifique, pour l\'affichage de la valeur du flux sur le diagramme de Sankey',
            affichage: 'Texte de remplacement de l\'affichage de la valeur du flux sur le diagramme de Sankey'
          }
        },
        apparence: {
          apparence: 'Apparence',
          couleur: 'Couleur',
          grad: 'Gradient',
          hach: 'Hachuré',
          of: 'Orientation',
          type: 'Type',
          courbe: 'Courbe',
          fleche: 'Flèche',
          structure: 'Structure',
          opacity: 'Opacité',
          recy: 'Recyclage',
          starting_curve: 'Position départ courbure',
          ending_curve: 'Position fin courbure',
          starting_tangeant: 'Courbure de départ',
          ending_tangeant: 'Courbure de fin',
          arrow_size: 'Taille de la flèche',
          display_link_lab: 'Afficher la valeur',
          hide_link_lab: 'Masquer la valeur',
          data_off_scale: 'Échelle',
          tooltips: {
            couleur: 'Choisir la couleur pour le/les flux sélectionné(s)',
            grad: 'Applique un effet de gradient de couleur sur le/les flux sélectionné(s). Le gradient est réalisé entre la couleur du noeud de départ et celle du noeud d\'arrivée',
            hach: 'Applique un effet de hachure sur le/les flux sélectionné(s)',
            of_vv: 'Permet d\'orienter le début du flux verticalement et la fin du flux verticalement',
            of_hh: 'Permet d\'orienter le début du flux horizontalement et la fin du flux horizontalement',
            of_hv: 'Permet d\'orienter le début du flux horizontalement et la fin du flux verticalement',
            of_vh: 'Permet d\'orienter le début du flux verticalement et la fin du flux horizontalement',
            courbe: 'Représente le/les flux sélectionné(s) sous forme de courbe(s) de Bezier.',
            fleche: 'Représente le/les flux sélectionné(s) avec une pointe de flèche à la fin.',
            structure: 'Représente le/les flux sélectionné(s) comme si il n\'avait pas de valeur peu importe leur vraie valeur ',
            recy: 'Représente le/les flux sélectionné(s) sous forme de recyclage, ie. avec un retour vers l\'arrière.',
            starting_curve: 'Permet d\'affiner la position du départ des courbures du/des flux sélectionné(s). Cette valeur est un ratio (%) relatif à la longueur du flux à partir du point de départ.',
            ending_curve: 'Permet d\'affiner la position de fin des courbures du/des flux sélectionné(s). Cette valeur est un ratio (%) relatif à la longueur du flux à partir du point de départ.',
            starting_tangeant: 'Paramétrage de la courbure de départ dans le cas ou le/les flux sélectionné(s) sont sous forme de courbe(s) de Bezier',
            ending_tangeant: 'Paramétrage de la courbure de fin dans le cas ou le/les flux sélectionné(s) sont sous forme de courbe(s) de Bezier',
            arrow_size: 'Modifie la taille de la flèche (largeur entre la fin du flux et le noeud',
            data_off_scale: 'Définie une échelle local pour l\'épaisseur du flux, cela peut casser la cohérence du diagramme au niveau de la proportionalité entre la valeur des flux et leur épaisseur '

          }
        },
        label: {
          label: 'Label',
          vdb: 'Valeur',
          len: 'Label en noir',
          lb: 'Label en blanc',
          lec: 'Label en couleur',
          acf: 'Orienter suivant l\'axe du flux',
          pos: 'Positionnement',
          milieu: 'Milieu',
          deb: 'Début',
          fin: 'Fin',
          dessous: 'Dessous',
          dessus: 'Dessus',
          pls: 'Positionnement manuel du label',
          scientificNotation: 'Notation scientifique',

          significantDigits: 'Chiffres significatifs',
          custom_digit: 'Décimales',
          NbDigit: 'Nombre maximum de décimales',
          l_u_v: 'Unité',
          l_u: 'Nom de l\'unité',
          unit_factor: 'Facteur d\'unité',
          tooltips: {
            label: 'Permet d\'afficher ou non le label (donnée / texte) associé au(x) flux sélectionné(s)',
            len: 'Afficher le texte du label en noir, pour le(s) flux sélectionné(s)',
            lb: 'Afficher le texte du label en blanc, pour le(s) flux sélectionné(s)',
            lec: 'Afficher le texte du label avec la/les même(s) couleur(s) que celle(s) associée(s) au(x) flux sélectionné(s)',
            acf: 'Pour le/les flux sélectionné(s), permet d\'orienter le texte du label en suivant la forme du flux associé',
            deb: 'Pour le/les flux sélectionné(s), permet de positionner le texte du label vers le point de départ du flux',
            milieu_h: 'Pour le/les flux sélectionné(s), permet de positionner le texte du label entre le point de départ et le point d\'arrivée du flux',
            fin: 'Pour le/les flux sélectionné(s), permet de positionner le texte du label vers le point d\'arrivée du flux',
            dessous: 'Pour le/les flux sélectionné(s), permet de positionner le texte du label en dessous du flux',
            milieu_v: 'Pour le/les flux sélectionné(s), permet de positionner le texte du label dans le flux',
            dessus: 'Pour le/les flux sélectionné(s), permet de positionner le texte du label au dessus du flux',
            pls: 'La combinaison Alt/click gauche déplace le label du/des flux sélectionné(s)',
            scientificNotation: 'Activer ou non la notation en format scientifique, pour l\'affichage de la valeur du flux sur le diagramme de Sankey',
            significantDigits: 'Nombre maximum de chiffres significatifs',
            custom_digit: 'Nombre maximum de décimales fixes',
            NbDigit: 'Nombre maximum de décimales fixes',

            l_u_v: 'Permet d\'afficher ou non l\'unité du flux',
            l_u: 'Permet de choisir le nom de l\'unité',
            unit_factor: 'Facteur de conversions de l\'unité du flux',
          }
        },
        layout: 'Organiser',
        layoutUp: 'Avancer',
        layoutTop: 'Premier plan',
        layoutDown: 'Reculer',
        layoutBottom: 'Dernier plan',
      },

      Banner: {
        data: 'Données',
        filter: 'Filtres',
        fdf: 'Filtrage des flux',
        fdn: 'Filtrage des noeuds',
        ndd: 'Niveaux de détail',
        ndd_lst: 'Groupes d\'étiquettes',
        ndd_chk: 'Appliquer les couleurs associées',
        sdr: 'Choisir le type de données affichées',
        sdd: 'Sélection des données',
        tl: 'Téléchargements',
        rslt: 'Téléchargement résultats',
        p_aff: 'Paramètres d\'affichage',
        p_aff_aff_links: 'Paramètres d\'affichage des flux',
        p_aff_filtre_links: 'Paramètres de filtrage visuel des flux',
        type_value: 'Type de valeur des flux',
        t_v_s: 'Structure',
        t_v_c: 'Données collectées',
        t_v_r: 'Données réconciliées',
        t_v_i: 'Intervalle',
        t_v_pv: 'Valeur possible',
        indetermined_value: 'Type de valeur des flux indeterminés',
        filtre: 'Valeur seuil pour l\'affichage des flux',
        fl: 'Valeur seuil pour l\'affichage des labels',
        fn: 'Flux nuls visibles',
        visible: 'Visible',
        hlp_1: 'Les diagrammes de Sankey',
        hlp_1_txt_2: 'Choisir le niveau d\'agrégation du diagramme',
        hlp_1_txt_3: 'Des filtres peuvent être utilisés pour n\'afficher que des parties du diagramme. Pour cela utiliser les selecteurs',
        hlp_1_txt_4: 'Différents palettes de couleurs peuvent être utiliser pour colorer les noeuds et les flux en utilisant le sélecteur Palette de Couleurs',
        hlp_1_txt_5: 'La structure du diagramme (sans épaisseur de flux) peut être affiché en cochant Structure du diagramme',
        hlp_1_txt_6: 'Le diagramme peut être ajusté à l\'écran en cochant Ajuster à l\'écran',
        hlp_1_txt_7: 'Pour obtenir des informations sur chaque flux, appuyer sur shift et passer la souris sur le flux.',
        hlp_1_txt_8: 'Paramétrer l\'affichage',
        hlp_1_txt_9: 'Menu déroulant pour filtrer les noeuds/flux/données selon leur groupe d\'étiquette associée ou bien pour appliquer des couleurs au diagramme avec la palette de couleurs du groupe d\'étiquette.',
        tooltipAdjustH: 'Réajuster horizontalement la zone de dessin à la taille de l\'écran',
        tooltipAdjustV: 'Réajuster verticalement la zone de dessin à la taille de l\'écran',
        tooltipStructure: 'Paramétrage visuel de la structure du diagramme',
        tooltipSelection: 'Sélectionner ou déplacer un élément',
        tooltipHelp: 'Affiche le dialogue d\'aide',
        tooltipLiason: 'Créer un noeud ou un flux',
        select_sibling: 'Selectionner ses frères ',
        fullscreen: 'Passer en mode mode plein écran',
        quit_fullscreen: 'Quitter le mode plein écran',
        hlp_node_tag_filter: 'Filtrer les noeuds et appliquer la couleur des étiquettes',
        hlp_link_tag_filter: 'Filtrer les flux et appliquer la couleur des étiquettes',
        hlp_data_tag_filter: 'Choisir les données et appliquer la couleur des étiquettes',

      },
      welcome: {
        welcome: 'Bienvenue sur l\'application web OpenSankey',
        breadcrumbs: {
          intro: 'Fonctionnalités',
          interface: 'Boutons et Menus',
          rc: 'Raccourcis',
          licence: 'Licences',
        },
        read_me: 'Lisez moi',
        intro: 'Présentation rapide de l\'outils et de ses fonctionnalités',
        caroussel: {
          Image1: 'Comprenez vos flux, Représentez les avec des diagrammes de Sankey',
          Image2: 'Importez rapidement vos données ou tracez directement vos diagrammes',
          Image3: 'Clarifiez l\'information représentée',
          Image40: 'Donnez la profondeur nécessaire à la compréhension',
          Image41: 'Donnez la profondeur nécessaire à la compréhension',
          Image5: 'Créez de véritables infographies didactiques',
          Image6: 'Créez de véritables infographies didactiques',
          descr: {
            Image1: 'Dans ce mode de réprésentation, l\'épaisseur de chaque flèche est proportionnelle à la valeur du flux qu\'elle représente.',
            Image2: 'Créez vos diagrammes à partir de tableurs Excel ou via l\'espace de dessin interactif',
            Image3: 'Facilitez la lecture de vos diagrammes grâce au système intégré d\'étiquetage des noeuds, des flux et des données',
            Image40: 'Les niveaux d\'agregations permettent de representer vos flux suivant plusieurs niveaux de détails',
            Image41: 'Chaque niveau de détails peut être individuellement selectionnés pour afficher seulement ce qui est utile',
            Image5: 'Expliquez simplement vos réalisations avec le système de légende automatique et l\'ajout de zones de texte',
            Image6: 'Créez de beaux diagrammes en y intégrant directement des images ou des icônes'
          }
        },
        interface: 'Présentation des actions associées aux boutons et menus',
        rc: 'Pour plus de rapidité, des raccourcis clavier sont disponibles',
        licence: 'Optez pour la licence adaptée à votre usage',
        1: 'Bouton de sélection du mode de la souris, il existe 2 modes : un mode permettant de sélectionner les noeuds et flux pour pouvoir les déplacer, puis un mode permettant de créer des liens et noeuds aux cliques de la souris',
        2: 'Boutons permettant de filtrer la visibilité ou couleurs des noeuds et flux en fonction des groupes auxquels ils sont associés',
        3: 'Bouton permettant de filtrer les noeuds en fonction de leur niveau d\'agrégation',
        4: 'Bouton permettant de filtrer la visibilité des flux en fonction de leurs valeurs, ce bouton permet aussi de modifier l\'échelle du diagramme (l\'épaisseur des flux)',
        5: 'Bouton permettant de réajuster la taille de la zone de dessin pour que tous les éléments soient visibles',
        6: 'Bouton permettant d\'afficher le diagramme sans prendre en compte la valeur des flux',
        7: 'Bouton permettant d\'afficher de l\'aide supplémentaire',
        8: 'Menu de navigation permettant d\'ouvrir, enregistrer, éditer des sankeys',
        9: 'Bouton permettant de se connecter avec un compte opensankey et activer des modules supplémentaires',
        10: 'Bouton permettant d\'ouvrir le menu de configuration qui permet d\'éditer les éléments du diagramme de sankey',
        excel: 'Bouton permettant de télécharger les données au format Excel'
      },
      useTemplate: 'Utiliser ce modèle',
      DisplayWelcome: 'Accueil',
      dontSeeAgain: 'Ne plus montrer',
      scale: 'Échelle',
      useTutoJSON: 'Ouvrir',
      useTutoExcel: 'Ouvrir Excel',
      menuTuto: 'Ouvrir menu tutoriel',
      desire_to_know_more: 'En savoir +',
      contribute_to_os: 'En savoir +',
      legal: 'Mention légales',
      tdr: 'Tous droits réservées',
      fullscreen: 'Activer le mode plein écran',
      exitFullscreen: 'Quitter le mode plein écran',
      Avancé: 'Avancé',
      separator_interval: ';',
      sep_decimal: ',',
    }
  }
}
